<template>
  <div class="testhistory">
    <!-- <el-button @click="">重置</el-button> -->
    <div class="testhistory-front">
      <div class="front-bo">
        <el-button icon="el-icon-arrow-left" @click="returnPage">返回</el-button>
        <div class="personalinfor">
          <div class="information">
            <span>姓名：{{ childData.childrenName }}</span>
            <span class="xl">学号：{{ childData.studentId }}</span>
          </div>
          <div class="information">
            <span>性别：{{ childData.sex }}</span>
            <span class="xl">年级：{{ childData.grade }}</span>
          </div>
          <div class="information">
            <span>身份证号码：{{ childData.idCard }}</span>
            <span class="xl">班级：{{ childData.className }}</span>
          </div>
          <div class="information">
            <span>出生日期：{{ childData.birthday }}</span>
            <span class="xl">监护人手机号：{{ childData.phone }}</span>
          </div>
          <!-- <div class="information">
            <span>监护人手机号：{{childData.phone}}</span>
          </div> -->
        </div>
      </div>
      <div class="chart">
        <div class="chart_se">

          <!-- </div> -->
          <!-- <div class="three_top-header">
            <div class="header-link"></div>
            <p class="header-title">正常人数与异常人数趋势</p>
          </div> -->
          <div
            class="GetSchoolNumsLineData-container"
            id="GetSchoolNumsLineData"
          ></div>
          <div class="dropdown-list">
            <!-- <div
              class="dropdown-item"
              @click="schoolGetSchoolTop1()"
              v-for="item in schoolGetNewGradeData"
              :key="item.id"
            >
              <p class="dropdown-item_title">
                {{ item.gradeName }}
                裸眼视力
              </p>
            </div> -->
            <el-select
              v-model="visionType"
              placeholder="请选择"
              @change="xuanChange"
            >
              <el-option
                v-for="item in options"
                :key="item.visionType"
                :label="item.label"
                :value="item.visionType"
              >
              </el-option>
            </el-select>
          </div>
          <div class="meeting">
            <!-- <div style="margin-top: 20px"> -->
            <el-radio-group v-model="radio" size="mini" @change="yearChange">
              <el-radio-button label="日"></el-radio-button>
              <el-radio-button label="周"></el-radio-button>
              <el-radio-button label="月"></el-radio-button>
              <el-radio-button label="年"></el-radio-button>
            </el-radio-group>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="foot-t">
        <div class="history_c">查看测试历史</div>
        <div>
          <el-date-picker
            v-model="value1"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="riChange">
          </el-date-picker>
        </div>
      </div>
      <div class="foot-z">
        <!-- <div class="body-container"> -->
        <el-table
          :data="childrenList"
          @selection-change="handleSelectionChange"
          :cell-style="rowClass"
          :header-cell-style="{ textAlign: 'center' }"
          stripe
          :max-height="maxHeight"
          style="width: 100%"
        >
          <el-table-column
            :formatter="visionData1"
            label="裸眼视力(右/左)"
            width="172"
          >
          </el-table-column>
          <el-table-column
            :formatter="visionData2"
            label="戴镜视力(右/左)"
            width="172"
          >
          </el-table-column>
          <el-table-column
            :formatter="MirrorBall"
            width="172"
            label="球镜(右/左)"
          >
          </el-table-column>
          <el-table-column
            :formatter="ColumnBirror"
            width="172"
            label="柱镜(右/左)"
          >
          </el-table-column>
          <el-table-column
            :formatter="AxialPosition"
            width="172"
            label="轴位(右/左)"
          >
          </el-table-column>
          <el-table-column prop="visionIsNormal" width="172" label="结果">
          </el-table-column>
          <el-table-column prop="visionDate" width="172" label="检查日期">
          </el-table-column>
          <el-table-column prop="testSource" width="172" label="数据来源">
          </el-table-column>
          <el-table-column prop="screeningName" width="172" label="筛查计划名称">
          </el-table-column>
        </el-table>
        <!-- </div> -->
      </div>
      <div class="foot-w">
        <div class="nation">
          <pagination
            :current-page="show.startPage"
            :total="show.totalPage"
            :page-size="show.pageSize"
            @currentChange="handleCurrentChange"
            @sizeChange="setPageSize"
            :page-sizes="show.pageSizes"
          ></pagination>
          <!-- <el-pagination
            background
            @size-change="setPageSize"
            @current-change="handleCurrentChange"
            :current-page.sync="show.startPage"
            :page-size="show.pageSize"
            layout="prev, pager, next, jumper"
            :total="show.totalPage">
          </el-pagination> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import storage from '@/globals/storage/index.js'
import GradeClassService from '@/globals/service/management/GradeClass.js'
import newDate from '@/utils/newDate.js'

export default {
  data () {
    return {
      // pickerOptions: {
      //   disabledDate: (time) => {
      //     console.log('time', time)
      //     return time.getTime() > new Date().getTime()
      //   }
      // },
      childrenList: [],
      GetSchoolNumsLineData: {},
      // testRecordList: '',
      childData: '',
      maxHeight: document.documentElement.clientHeight - 320,
      show: {
        startPage: 1,
        pageSize: 8,
        pageSizes: [8],
        isGovernment: 0,
        totalPage: 1
      },
      startTime: null,
      // schoolGetNewGradeData: ['裸眼视力', '戴镜视力', '屈光情况'],
      overTime: null,
      // visionType: '',
      childrenId: '',
      uid: '',
      loginKey: '',
      leftEyeVision: '',
      rightEyeVision: '',
      timeType: '',
      value1: '',
      value2: [],
      radio: '日',
      xdateList: '',
      options: [
        {
          visionType: '0',
          label: '裸眼视力'
        },
        {
          visionType: '1',
          label: '戴镜视力'
        },
        {
          visionType: '2',
          label: '屈光情况'
        }
      ],
      visionType: '0'
    }
  },
  created () {
    this.overTime = Date.now()
    const sers = new Date(this.overTime)
    // console.log('现在的', this.overTime)
    this.startTime = sers.setDate(sers.getDate() - 365)
    // console.log('一年前', sers.setDate(sers.getDate() - 365))
    this.startTime = this.overTime - 31536000000

    this.getData()
    this.childData = storage.get('childData')
    this.loginKey = storage.get('loginKey')
    this.uid = storage.get('uid')
    this.childrenId = this.childData.childrenId
    // this.setGetSchoolNumsLineData()
    // this.yearChange()
    // this.xuanChange()
    // this.testhistoryxhe()
  },
  mounted () {
    this.yearChange()
    this.xuanChange()
    this.testhistoryxhe()
    this.studentTest()
  },
  methods: {
    AxialPosition (row) {
      // return row.refractiveRightAxis + ' / ' + row.refractiveLeftAxis
      if (row.refractiveLeftAxis === '-' && row.refractiveRightAxis === '-') {
        return '-'
      } else {
        let left = '-'
        let right = '-'
        if (row.refractiveLeftAxis) {
          left = row.refractiveLeftAxis
        }
        if (row.refractiveRightAxis) {
          right = row.refractiveRightAxis
        }
        return right + ' / ' + left
      }
    },
    ColumnBirror (row) {
      if (row.refractiveLeftDc === '-' && row.refractiveRightDc === '-') {
        return '-'
      } else {
        let left = '-'
        let right = '-'
        if (row.refractiveLeftDc) {
          left = row.refractiveLeftDc
        }
        if (row.refractiveRightDc) {
          right = row.refractiveRightDc
        }
        return right + ' / ' + left
      }
    },
    MirrorBall (row) {
      // return row.refractiveRightDs + ' / ' + row.refractiveLeftDs
      if (row.refractiveLeftDs === '-' && row.refractiveRightDs === '-') {
        return '-'
      } else {
        let left = '-'
        let right = '-'
        if (row.refractiveLeftDs) {
          left = row.refractiveLeftDs
        }
        if (row.refractiveRightDs) {
          right = row.refractiveRightDs
        }
        return right + ' / ' + left
      }
    },
    visionData1 (row) {
      if (row.leftVisionData === '-' && row.rightVisionData === '-') {
        return '-'
      } else {
        let left = '-'
        let right = '-'
        if (row.leftVisionData) {
          left = row.leftVisionData
        }
        if (row.rightVisionData) {
          right = row.rightVisionData
        }
        return right + ' / ' + left
      }
    },
    visionData2 (row) {
      // return row.wearRightVisionData + ' / ' + row.wearLeftVisionData
      if (row.wearLeftVisionData === '-' && row.wearRightVisionData === '-') {
        return '-'
      } else {
        let left = '-'
        let right = '-'
        if (row.wearLeftVisionData) {
          left = row.wearLeftVisionData
        }
        if (row.wearRightVisionData) {
          right = row.wearRightVisionData
        }
        return right + ' / ' + left
      }
    },
    returnPage () {
      // if (window.history.length <= 1) {
      //   // this.$router.push({ path: "/system/storageManagement" })
      //   return false
      // } else {
      this.$router.go(-1)
      // }
    },
    yearChange (value) {
      if (value === '年') {
        this.timeType = 3
      } else if (value === '周') {
        this.timeType = 1
      } else if (value === '月') {
        this.timeType = 2
      } else {
        this.timeType = 0
      }
      // console.log('this.timeType', this.timeType)
      this.testhistoryxhe()
    },
    // 选择器
    xuanChange (visionType) {
      // console.log('visionType', visionType)
      if (visionType) {
        // console.log('123')
        this.visionType = visionType
      }
      this.testhistoryxhe()
    },
    // schoolGetSchoolTop1 () {
    //   console.log('1')
    // },
    setPageSize (value) {
      this.show.pageSize = value
      this.studentTest()
    },
    handleCurrentChange (val) {
      // console.log('val', val)
      this.show.startPage = val
      this.studentTest()
    },
    getData () {
      this.childData = this.$route.params.item
      if (this.childData) {
        storage.set('childData', this.childData)
      }
      // console.log('childData', this.childData)
      // for (const key in data) {
      //   this.studentFrom[key] = data[key]
      // }
    },
    handleSelectionChange (value) {
      console.log('value', value)
      // this.childrenListSelection = value
    },
    rowClass ({ row, rowIndex }) {
      // if (this.selectRow.includes(row.childrenId)) {
      if (rowIndex % 2 === 0) {
        return { 'text-align': 'center' }
      } else {
        return {
          'background-color': 'rgba(243, 245, 245, 1)',
          'text-align': 'center'
        }
      }
    },
    setGetSchoolNumsLineData () {
      const GetSchoolNumsLineData = document.getElementById(
        'GetSchoolNumsLineData'
      )
      const myChart = this.$echarts.init(GetSchoolNumsLineData)
      const option = {
        // title: {
        //   text: 'Stacked Line'
        // },
        tooltip: {
          trigger: 'axis'
          // axisPointer: {
          //   type: 'shadow'
          // }
        },
        legend: {
          data: ['左眼', '右眼'],
          padding: [20, 0, 0, 0]
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            // 坐标轴 轴线
            show: false // 是否显示
          },
          axisTick: { // 坐标轴标签
            // show: false // 是否显示
            lineStyle: {
              color: '#e0e6f1'
            }
          },
          data: this.GetSchoolNumsLineData.xdateList.reverse()
          // data: ['2022-04-11', '2022-04-10', '2022-04-09', '2022-04-08', '2022-04-07', '2022-04-06']
          // axisLabel: {
          //   show: true,
          //   interval: 0,
          //   rotate: 40,
          //   textStyle: {
          //     color: '#000'
          //   }
          // }
        },
        yAxis: {
          type: 'value'
          // minInterval: 0.4,
          // min: 4.0,
          // max: 5.6
          // boundaryGap: false,
          // data: [4.0, 4.4, 4.8, 4.2, 5.6]
          // axisPointer: {
          //   type: 'line'
          // }
        },
        series: [
          {
            name: '左眼',
            type: 'line',
            // stack: 'Total',
            emphasis: {
              focus: 'series'
            },
            // data: [4.3, 4.5, 4.6, 4.6, 5.3, 5.0]
            data: this.leftEyeVision.reverse()
          },
          {
            name: '右眼',
            type: 'line',
            // stack: 'Total',
            emphasis: {
              focus: 'series'
            },
            // data: [4.5, 4.7, 5.3, 5.0, 4.6, 5.0]
            data: this.rightEyeVision.reverse()

          }
        ]
      }
      myChart.setOption(option)
    },
    testhistoryxhe () {
      // this.loginKey = storage.get('loginKey')
      // this.uid = storage.get('uid')
      // this.childrenId = this.childData.childrenId
      GradeClassService.trendChart({
        childrenId: this.childrenId,
        visionType: Number(this.visionType),
        timeType: this.timeType,
        loginKey: this.loginKey,
        uid: this.uid
      }).then((res) => {
        // console.log('res', res)
        this.GetSchoolNumsLineData = res.data
        if (this.visionType === '0') {
          this.leftEyeVision = this.GetSchoolNumsLineData.yleftVisionList
          this.rightEyeVision = this.GetSchoolNumsLineData.yrightVisionList
        } else if (this.visionType === '1') {
          this.leftEyeVision = this.GetSchoolNumsLineData.yleftVisionList
          this.rightEyeVision = this.GetSchoolNumsLineData.yrightVisionList
        } else if (this.visionType === '2') {
          this.leftEyeVision = this.GetSchoolNumsLineData.yleftRefractionList
          this.rightEyeVision = this.GetSchoolNumsLineData.yrightRefractionList
        }
        // this.xdateList = this.GetSchoolNumsLineData.xdateList
        // console.log('xdateList', this.GetSchoolNumsLineData.xdateList)
        // console.log('23', this.rightEyeVision, this.leftEyeVision)
        this.setGetSchoolNumsLineData()
      })
    },
    studentTest () {
      // this.overTime = Date.now()
      // const sers = new Date(this.overTime)
      // // console.log('现在的', this.overTime)
      // this.startTime = sers.setDate(sers.getDate() - 365)
      // // console.log('一年前', sers.setDate(sers.getDate() - 365))
      // this.startTime = this.overTime - 31536000000

      // this.startTime = new Date(this.overTime).setDate(new Date(this.overTime).getDate() - 365)
      // console.log(this.overTime, this.startTime)
      GradeClassService.getChildrenTestHistory({
        childrenId: this.childrenId,
        startPage: this.show.startPage,
        pageSize: this.show.pageSize,
        startTime: this.startTime,
        overTime: this.overTime,
        loginKey: this.loginKey,
        uid: this.uid
      }).then((res) => {
        // console.log('res', res)
        // this.testRecordList = res.data
        this.childrenList = res.data.testRecordList.map(item => {
          item.leftVisionData = item.leftVisionData !== null ? item.leftVisionData : '-'
          item.rightVisionData = item.rightVisionData !== null ? item.rightVisionData : '-'
          item.refractiveLeftDc = item.refractiveLeftDc !== null ? item.refractiveLeftDc : '-'
          item.refractiveLeftDs = item.refractiveLeftDs !== null ? item.refractiveLeftDs : '-'
          item.refractiveRightDc = item.refractiveRightDc !== null ? item.refractiveRightDc : '-'
          item.refractiveRightDs = item.refractiveRightDs !== null ? item.refractiveRightDs : '-'
          item.refractiveLeftAxis = item.refractiveLeftAxis !== null ? item.refractiveLeftAxis : '-'
          item.refractiveRightAxis = item.refractiveRightAxis !== null ? item.refractiveRightAxis : '-'
          item.wearLeftVisionData = item.wearLeftVisionData !== null ? item.wearLeftVisionData : '-'
          item.wearRightVisionData = item.wearRightVisionData !== null ? item.wearRightVisionData : '-'
          item.screeningName = item.screeningName !== null ? item.screeningName : '-'
          if (item.testSource === 0) {
            item.testSource = '视觉健康云App'
          } else if (item.testSource === 1) {
            item.testSource = '智能视力表'
          } else if (item.testSource === 2) {
            item.testSource = '视力测试系统小程序'
          } else if (item.testSource === 3) {
            item.testSource = '电脑验光仪'
          } else if (item.testSource === 4) {
            item.testSource = '导入'
          } else if (item.testSource === 5) {
            item.testSource = 'IPTV'
          }
          if (item.visionDate) item.visionDate = newDate.formatDate(new Date(Number(item.visionDate)))
          return item
        })
        this.show.totalPage = res.data.totalPage
      })
      // this.value1 = this.value1.concat(this.timestampToTime(Number(this.startTime)), this.timestampToTime(Number(this.overTime)))
      // console.log('this.value1', this.value1)
    },
    riChange (value) {
      // console.log('value', value)
      if (value) {
        this.startTime = new Date(value[0]).getTime()
        this.overTime = new Date(value[1]).getTime() - 1
        // this.overTime = new Date(value[0]).getTime()
        console.log(this.startTime, this.overTime)
        this.studentTest()
      }
    },
    // 时间戳转时间，精确到秒
    timestampToTime (timestamp) {
      var date = new Date(timestamp) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
      return Y + M + D
    }

  }
}
</script>

<style lang="less" scoped>
.testhistory {
  width: 100%;
  // height: 100%;
  overflow-x: auto;
  background-color: #f5fbff;
  padding: 27px 64px;
  .testhistory-front {
    display: flex;
    flex-direction: row;
  }
  .front-bo {
    min-width: 614px;
    margin-right: 42px;
  }
  .personalinfor {
    position: relative;
    width: 614px;
    height: 226px;
    margin-top: 30px;
    // height: 100%;
    border-radius: 10px;
    color: #000;
    box-shadow: 0px 20px 40px 1px rgba(0, 0, 0, 0.08);
    // margin-left: 32px;
    // background-image: url('./../../assets/management/Group.png');
    background: url('./../../assets/management/Group.png') no-repeat center
      center;
    .information {
      margin-left: 32px;
      padding-top: 26px;
    }
    .xl {
      position: absolute;
      left: 357px;
    }
  }
  .chart {
    position: relative;
    background-color: #fff;
    color: #000;
    height: 298px;
    min-width: 896px;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 10px 20px 1px rgba(0, 0, 0, 0.08);
    .chart_se{
      width: 100%;
      height: 100%;
    }
    // border: 1px solid #000;
    .GetSchoolNumsLineData-container {
      // flex: 1;
      height: 100%;
    }
    .body-three_top {
      min-height: 470px;
    }
    .dropdown-list {
      position: absolute;
      top: 5px;
      right: 10px;
    }
    ::v-deep .el-input--suffix .el-input__inner {
      width: 120px;
    }
    ::v-deep .el-input__inner {
      border: 1px solid #34bbec;
    }
    .meeting {
      position: absolute;
      top: 10px;
      left: 10px;
    }
    // .dropdown-item {
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   height: 34px;
    //   padding: 0 12px;
    //   height: 34px;
    //   line-height: 34px;
    //   color: #c0c4cc;
    //   border: 1px solid #34bbec;
    //   font-size: 14px;
    //   .dropdown-item_title {
    //     cursor: default;
    //   }
    //   &:last-child {
    //     border-bottom: none;
    //   }
    //   &:hover {
    //     background-color: #0f67aa;
    //   }
    // }
  }
  .foot {
    background-color: #fff;
    color: #000;
    margin-top: 26px;
    max-height: 606px;
    min-width: 1552px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px 1px rgba(0, 0, 0, 0.08);
    // text-align: center;
  }
  .foot-t {
    height: 50px;
    // border: 1px solid #000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 24px;
    line-height: 54px;
    .history_c {
      font-size: 22px;
      // font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #34bbec;
    }
  }
  ::v-deep .foot-t .el-date-editor .el-range-separator {
    padding: 0;
  }
  .foot-z {
    height: 465px;
    flex: 1;
    // border: 1px solid #000;
    // ::v-deep thead .el-table tr{
    //   background-color: #9dd7f0;
    // }
  }
  .foot-w {
    height: 50px;
    line-height: 50px;
    text-align: center;
    .nation {
      margin-top: 8px;
    }
  }
}
</style>
